import React, { useContext } from "react";
import { ScrollView, View } from "react-native";
import ReceiptDetailSection, {
  DetailItem,
} from "../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../lib/i18n";
import {
  formatCurrency,
  formatDateTime,
  getTimeSpent,
} from "../../../lib/utils";
import ReceiptHeader from "./ReceiptHeader";
import { styles } from "./styles";
import { buildChargeData } from "./utils";
import ReceiptFooter from "./ReceiptFooter";
import StyledScreen from "src/components/StyledScreen";
import { config } from "src/lib/config";
import { MasterContext } from "src/lib/masterContext";

export const ReceiptDetail = ({
  order,
  onContest,
}: {
  order: ApiOrderDetail;
  onContest: () => void;
}) => {
  const {
    featureFlags: {
      hideProcessTime,
      productsTaxEnabled,
      orderDetailsShowDetailsAtTheTop,
      orderDetailsShowFooterOrderId,
      contestingEnabled,
    },
    appConfig: { timezone },
  } = useContext(MasterContext);
  const created = formatDateTime(order.createdAt, timezone);
  const processTime = order
    ? getTimeSpent(order.createdAt, order.processedAt)
    : "";

  const getTitle = (item: ApiLineItem) => {
    if (item.quantity > 1) {
      return `${item.quantity} x ${item.name}`;
    }
    return item.name;
  };

  const dataProduct = order.lineItems.map((item) => ({
    title: getTitle(item),
    value: formatCurrency(item.totalPrice, order.currencyCode, item.quantity),
    status: item.status,
    leftViewStyle: { wordBreak: "break-word" },
  }));

  const chargeData = buildChargeData(order, formatCurrency, productsTaxEnabled);

  const dateAndTimeData = [
    {
      title: translate("receiptDetailScreen.date"),
      value: `${created.date} ${created.time}`,
    },
  ] as DetailItem[];

  if (!hideProcessTime) {
    dateAndTimeData.push({
      title: translate("receiptDetailScreen.processTime"),
      value: processTime,
    });
  }

  const showReportProblem =
    contestingEnabled &&
    order.status === "paid" &&
    order.lineItems.filter((i) => i.rin).length === order.lineItems.length;

  let footerText = config.messages.footerPaid;
  if (order.status === "contested") {
    footerText = config.messages.footerInReview;
  } else if (order.status === "reviewed") {
    footerText = config.messages.footerReviewed;
  }

  return (
    <StyledScreen>
      <ScrollView style={styles.scroll}>
        <View style={styles.content}>
          <ReceiptHeader order={order} />
          <ReceiptDetailSection name="product" data={dataProduct} />
          <ReceiptDetailSection name="charge" data={chargeData} />
          {!orderDetailsShowDetailsAtTheTop && (
            <ReceiptDetailSection name="date" data={dateAndTimeData} />
          )}
        </View>
        <ReceiptFooter
          orderId={orderDetailsShowFooterOrderId && order.id}
          showSubmit={showReportProblem}
          extraText={order.extraText}
          onSubmit={() => {
            onContest();
          }}
          footerText={footerText}
        />
      </ScrollView>
    </StyledScreen>
  );
};
