import React, { useContext } from "react";
import { View, Text } from "react-native";
import { DashLine } from "../../../../components/v2/Dashline";
import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { styles } from "./styles";
import { styles as parentStyles } from "../styles";
import { useFlavor as withFlavor } from "../../../../lib/hooks/useFlavor";
import { Flavor } from "src/lib/flavors";
import { ReceiptLogo } from "src/components/ReceiptLogo";
import { buildHeaderTag, buildHeaderValue } from "../utils";
import { MasterContext } from "src/lib/masterContext";
import { formatDateTime } from "src/lib/utils";

type IReceiptHeader = {
  order: ApiOrderDetail;
  flavor: Flavor;
};

export const ReceiptHeader = ({ order, flavor }: IReceiptHeader) => {
  const {
    appConfig: { timezone },
    featureFlags,
  } = useContext(MasterContext);
  const headerValue = buildHeaderValue(order);
  const headerRightView = buildHeaderTag(order);
  const created = formatDateTime(order.createdAt, timezone);
  const css = styles(flavor);

  const items = [];
  if (featureFlags.orderDetailsShowDetailsAtTheTop) {
    items.push({
      title:
        (translate("receiptDetailScreen.orderId") as string) + " " + order.id,
      value: `${created.date}\n${created.time}`,
      leftViewStyle: { marginBottom: 'auto' }
    });
  }
  if (featureFlags.orderDetailsShowHeader) {
    items.push({
      title: translate("receiptDetailScreen.paymentStatus") as string,
      value: headerValue,
      rightView: headerRightView,
    });
  }
  if (featureFlags.orderDetailsShowProductsListTitle) {
    items.push({
      title: translate("receiptDetailScreen.listOfProducts") as string,
    });
  }

  return (
    <View style={css.wrapper}>
      <View style={css.logoWrapper}>
        <ReceiptLogo />
        {featureFlags.orderDetailsShowHeader && (
          <Text style={parentStyles.title}>
            {translate("receiptDetailScreen.headerTitle") as string}
          </Text>
        )}
      </View>
      {featureFlags.orderDetailsShowHeader && <DashLine />}
      <ReceiptDetailSection name="status" data={items} />
    </View>
  );
};

export default withFlavor(ReceiptHeader);
