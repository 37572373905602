/* eslint-disable max-classes-per-file */
import {
  AiFiFlavor,
  LoopFlavor,
  ZabkaFlavor,
  StreetFlavor,
  MorrisonsFlavor,
  RioFlavor,
  Flavor,
  HebFlavor,
  AldiFlavor,
  VerizonFlavor,
  VanillaFlavor,
  ChengduFlavor,
  CoconutFlavor,
  ShanghaiFlavor,
  TRCAFlavor,
  BoxLLFlavor,
  MiamiFlavor,
  MSFTFlavor,
  DGFlavor,
  VerizonUltraFlavor,
  VerizonUltraUKFlavor,
  CainzFlavor,
  REWEFlavor,
  AVFCFlavor,
  LCFCFlavor
} from ".";

export enum AppFlavor {
  "CAINZ",
  "VERIZONULTRAUK",
  "VERIZONULTRA",
  "DG",
  "MSFT",
  "MIAMI",
  "LOOP",
  "ZABKA",
  "RIO",
  "STREET",
  "MORRISONS",
  "HEB",
  "ALDI",
  "VERIZON",
  "MASTER",
  "VANILLA",
  "CHENGDU",
  "COCONUT",
  "SHANGHAI",
  "TRCA",
  "BoxLL",
  "REWE",
  "LCFC",
  "AVFC"
}

const flavorNameToObject: { [key: string]: Flavor } = {
  Cainz: CainzFlavor,
  VerizonUltraUK: VerizonUltraUKFlavor,
  VerizonUltra: VerizonUltraFlavor,
  DG: DGFlavor,
  MSFT: MSFTFlavor,
  Miami: MiamiFlavor,
  Loop: LoopFlavor,
  Zabka: ZabkaFlavor,
  Rio: RioFlavor,
  Street: StreetFlavor,
  Morrisons: MorrisonsFlavor,
  HEB: HebFlavor,
  Aldi: AldiFlavor,
  Verizon: VerizonFlavor,
  Master: AiFiFlavor,
  Vanilla: VanillaFlavor,
  Coconut: CoconutFlavor,
  Shanghai: ShanghaiFlavor,
  TRCA: TRCAFlavor,
  BoxLL: BoxLLFlavor,
  Chengdu: ChengduFlavor,
  REWE: REWEFlavor,
  AVFC: AVFCFlavor,
  LCFC: LCFCFlavor,
};

const appFlavorToFlavorObject: { [key in AppFlavor]: Flavor } = {
  [AppFlavor.CAINZ]: CainzFlavor,
  [AppFlavor.VERIZONULTRAUK]: VerizonUltraUKFlavor,
  [AppFlavor.VERIZONULTRA]: VerizonUltraFlavor,
  [AppFlavor.DG]: DGFlavor,
  [AppFlavor.MSFT]: MSFTFlavor,
  [AppFlavor.MIAMI]: MiamiFlavor,
  [AppFlavor.LOOP]: LoopFlavor,
  [AppFlavor.ZABKA]: ZabkaFlavor,
  [AppFlavor.RIO]: RioFlavor,
  [AppFlavor.STREET]: StreetFlavor,
  [AppFlavor.MORRISONS]: MorrisonsFlavor,
  [AppFlavor.HEB]: HebFlavor,
  [AppFlavor.ALDI]: AldiFlavor,
  [AppFlavor.VERIZON]: VerizonFlavor,
  [AppFlavor.MASTER]: AiFiFlavor,
  [AppFlavor.VANILLA]: VanillaFlavor,
  [AppFlavor.CHENGDU]: ChengduFlavor,
  [AppFlavor.COCONUT]: CoconutFlavor,
  [AppFlavor.SHANGHAI]: ShanghaiFlavor,
  [AppFlavor.TRCA]: TRCAFlavor,
  [AppFlavor.BoxLL]: BoxLLFlavor,
  [AppFlavor.CHENGDU]: ChengduFlavor,
  [AppFlavor.REWE]: REWEFlavor,
  [AppFlavor.AVFC]: AVFCFlavor,
  [AppFlavor.LCFC]: LCFCFlavor,
};

const flavorToName: { [key in AppFlavor]: string } = {
  [AppFlavor.CAINZ]: "Cainz",
  [AppFlavor.VERIZONULTRAUK]: "VerizonUltraUK",
  [AppFlavor.VERIZONULTRA]: "VerizonUltra",
  [AppFlavor.DG]: "DG",
  [AppFlavor.MSFT]: "MSFT",
  [AppFlavor.MIAMI]: "Miami",
  [AppFlavor.LOOP]: "Loop",
  [AppFlavor.ZABKA]: "Zabka",
  [AppFlavor.RIO]: "Rio",
  [AppFlavor.STREET]: "Street",
  [AppFlavor.MORRISONS]: "Morrisons",
  [AppFlavor.HEB]: "HEB",
  [AppFlavor.ALDI]: "Aldi",
  [AppFlavor.VERIZON]: "Verizon",
  [AppFlavor.MASTER]: "Master",
  [AppFlavor.VANILLA]: "Vanilla",
  [AppFlavor.CHENGDU]: "Chengdu",
  [AppFlavor.COCONUT]: "Coconut",
  [AppFlavor.SHANGHAI]: "Shanghai",
  [AppFlavor.TRCA]: "TRCA",
  [AppFlavor.BoxLL]: "BoxLL",
  [AppFlavor.CHENGDU]: "Chengdu",
  [AppFlavor.REWE]: "REWE",
  [AppFlavor.LCFC]: "LCFC",
  [AppFlavor.AVFC]: "AVFC",
};

const getKeyFromMapOrThrow = (key: any, mapping: any) => {
  const returnVal = mapping[key];

  if (!returnVal) {
    throw new KeyNotInMapError(`Incorrect key ${key} for mapping ${mapping}`);
  }

  return returnVal;
};

export const getFlavor: (name: string) => Flavor = (name) =>
  getKeyFromMapOrThrow(name, flavorNameToObject);

export const getClassForFlavor: (flavor: AppFlavor) => Flavor = (flavor) =>
  getKeyFromMapOrThrow(flavor, appFlavorToFlavorObject);

export const getFlavorName: (flavor: AppFlavor) => string = (flavor) =>
  getKeyFromMapOrThrow(flavor, flavorToName);

export class NotImplementedError extends Error {}

class KeyNotInMapError extends Error {}
