import { ITranslation } from "./types";

export const en: ITranslation = {
  addCardScreen: {
    headerTitle: "Add card",
    stripeSetupIntentError: {
      header: "Error $$",
      body: "Something went wrong. Please try another card. Details: $$",
    },
  },
  button: {
    addCard: "Add card",
    completeRegistration: "Complete registration",
    confirm: "Confirm",
    continue: "Continue",
    getStarted: "Get started",
    goToPayment: "Payment Options",
    login: "Log in",
    removeCard: "Remove card",
    signup: "Sign up",
    back: "Back",
    skip: "Skip",
    error: "Error",
    payment: "Retry payment",
    ok: "Ok",
    yes: "Yes",
    no: "No",
  },
  contactUsScreen: {
    address: "Address",
    email: "Email",
    headerTitle: "Contact us",
    phone: "Phone",
    dataProtectionEmail: "Data Deletion",
    dataProtectionTitle:
      "If you would like to request your personal data to be deleted, please send us an email to: ",
  },
  api: {
    E_FORCED_LOGOUT: "You have been logged out your connection expired.",
  },
  errors: {
    error: "Error",
    // E_CONFLICT: 'User already exists',
    E_NO_INTERNET: "No internet connection",
    E_CUSTOMER_NOT_FOUND: "Customer was not found",
    E_CUSTOMER_INVALID_CREDENTIALS: "Invalid email or password",
    E_FORBIDDEN: "Access forbidden",
    E_NOT_FOUND: "Record not found",
    E_ORDER_NOT_FOUND: "Order does not exist",
    E_PAYMENT_ERROR: "Something went wrong when contacting our payment service",
    E_STORE_NOT_FOUND: "Store not found",
    E_UNAUTHORIZED: "Access not authorized",
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND:
      "Customer does not have payment method set up",
    E_INVALID_BODY: "Please fill in all fields or fields are in invalid format",
    E_ORDER_CUSTOMER_RELATION: "Order does not belong to customer",
    E_RESET_CODE_ALREADY_GENERATED:
      "Reset code for this email address has been already sent. Try again later.",
    E_RESET_CODE_INVALID: "Reset code is invalid",
    E_RESET_CODE_NOT_FOUND: "Reset token not found or expired",
    E_SHOPIFY_DUPLICATE_EMAIL: "Email is already taken",
    E_SHOPIFY_DUPLICATE_PHONE: "Phone is already taken",
    E_SHOPIFY_INVALID_ORDER_ID: "Order does not exist",
    E_SHOPIFY_CUSTOMER_NOT_FOUND: "Customer not found",
    E_CUSTOMER_NO_PAYMENT_METHOD:
      "Customer does not have payment method set up",
    E_CUSTOMER_UNSETTLED_TRANSACTIONS:
      "Your previous transaction failed. Contact our support.",
    E_CUSTOMER_CARD_EXPIRED: "Customer's card is expired",
    SOMETHING_WRONG: "Something went wrong. Please try again later.",
  },
  forgotPasswordScreen: {
    title: "Forgot password?",
    wrongCodeErrorMessage: "Incorrect code",
    wrongEmailErrorMessage: "Wrong email",
    codeErrorMessage: "Field is not allowed to be empty",
    emailErrorMessage: "Field is not allowed to be empty",
    passwordErrorMessage: "Field is not allowed to be empty",
    shortPasswordErrorMessage:
      "Password lenght must be at least 8 characters long",
    alreadyHaveCode: "Already have a code",
  },
  form: {
    email: "EMAIL ADDRESS",
    emailPlaceholder: "email@example.com",
    firstName: "FIRST NAME",
    firstNamePlaceholder: "John",
    lastName: "LAST NAME",
    lastNamePlaceholder: "Doe",
    newPassword: "New Password",
    password: "PASSWORD",
    passwordPlaceholder: "8+ characters",
    resetCode: "Reset code",
    resetCodePlaceholder: "12345",
    productName: "Product name",
    productQuantity: "Quantity",
    labelPasswordShow: "SHOW",
    labelPasswordHide: "HIDE",
    taxId: "tax id (optional)",
    taxIdPlaceholder: "1234567890",
    companyName: "Company name (optional)",
    companyNamePlaceholder: "Company Inc.",
    phone: "Phone",
    phonePlaceholder: "13888888888",
    address: "Address",
    addressPlaceholder: "123 Some City #456, 94123 CA",
    addressError: "address is not allowed to be empty",
    firstNameErrorMessage: "first name is not allowed to be empty",
    lastNameErrorMessage: "last name is not allowed to be empty",
    emailErrorMessage: "email is not allowed to be empty",
    invalidEmailErrorMessage: "This is not a valid email",
    passwordErrorMessage: "password is not allowed to be empty",
    shortPasswordErrorMessage:
      "password lenght must be at least 8 characters long",
    taxIdErrorMessage: "This is not a valid tax id",
    taxIdEmptyErrorMessage: "Tax id is not allowed to be empty",
    companyNameEmptyErrorMessage: "Company name is not allowed to be empty",
    phoneEmptyErrorMessage: "Phone is not allowed to be empty",
    phoneInvalidErrorMessage:
      "A phone number should be 8-15 digits(include country code)",
  },
  loginScreen: {
    forgotPassword: "Forgot password?",
    subtitle: "Log in with your email and password",
    title: "Welcome back",
  },
  homeScreen: {
    cardInvalidSubtitle:
      "please finish setting up your account in order to unlock access to our stores.",
    cardValidTitle: "Scan to enter",
    partyText: "Number of people in your party:",
    greeting: "Hello",
    errorUnpaid:
      "Youll have to pay all of your receipts to regain access to stores",
    errorUnpaidButton: "Review unpaid receipt",
    errorExpired: "All of your cards have expired. Please add a new card.",
    errorExpiredButton: "Add card",
    errorNoCard: "No card was added to your account. Please add a new card.",
    errorNoCardButton: "Add card",
    errorUnknown: "Unknown error",
  },
  menu: {
    access: "Access",
    payment: "Payment",
    receipts: "Receipts",
    settings: "Contact",
  },
  onboardingScreen: {
    subtitle1: "Enter the store by using your code in your app...",
    title1: "Scan to Enter",
    subtitle2:
      "Let them enter the store first, by scanning your code, then enter yourself.",
    title2: "Multiple People?",
    subtitle3:
      "Anything you take off the shelf is automatically added to your virtual cart.",
    title3: "Just Shop",
    subtitle4:
      "When you’re done, you can simply leave the store. You’ll receive your receipt.",
    title4: "Ready? Just Go",
  },
  registerLoginScreen: {
    register: "Register",
    login: "Log in",
  },
  paymentScreen: {
    card: {
      subtitle:
        "This is your current payment method on file to pay at the store.",
    },
    endsWith: "* * * * $$",
    noCard: {
      subtitle:
        "You don't have a payment method currently set up. Add a card by pressing the button bellow.",
    },
    removeCard: {
      cancel: "Cancel",
      delete: "Delete",
      subtitle: "Are you sure you want to remove your card?",
      title: "Remove card",
    },
    title: "Payment Method",
    addCreditCard: {
      cardView: {
        labels: {
          expiry: "MONTH/YEAR",
        },
      },
      labels: {
        name: "CARDHOLDER'S NAME",
        number: "CARD NUMBER",
        expiry: "EXPIRY",
        cvc: "CVC/CCV",
        postalCode: "POSTAL CODE",
      },
      placeholders: {
        name: "Full Name",
        number: "1234 5678 1234 5678",
        expiry: "MM/YY",
        cvc: "CVC",
        postalCode: "34567",
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: "Receipt Detail",
    quantity: "Quantity: $$",
    subtotal: "Subtotal",
    support: {
      body: "Enter details...\n\n---\nTransaction ID: $$",
      contactCta: "Correct me if I'm wrong",
      subject: "Support request",
    },
    tax: "Tax",
    taxCRV: "CRV",
    total: "Total",
    title: "Receipt",
    processTime: "Process time",
    shoppingTime: "Shopping time",
    emptyMessage:
      "Thank you for shopping with AiFi, you are not being charged this time. We look forward to your next visit!",
    problem:
      "Found a problem? Please send us the edited receipt for a review and we will look into it.",
    date: "Date",
    address: "Thank you for shopping with us.",
    noItems: "No items picked",
    paymentStatus: "Payment Status",
    draftProducts:
      "We’re processing your shopping journey, your receipt will be available shortly.",
    retryPaymentSuccessInfo: "Payment successful, thank you!",
    retryPaymentFailureInfo: "Payment did not succeed, please try again later.",
    orderId: "Order ID",
    feedbackSuccessTip: "Thanks for sharing your feedback!",
    remindLaterTip: "Great! We will reach out at a better time.",
    feedbackModalTitle:
      "How likely would you recommend this shopping experience to your friend?",
    feedbackModalTip: "Please rate on scale from 0 - 10",
    submitFeedback: "Submit Feedback",
    remindLater: "Remind me later",
    dontAskAgain: "Don’t ask again",
    notLikely: "Not Likely",
    veryLikely: "Very Likely",
    listOfProducts: "Products"
  },
  receiptDetailComplain: {
    title: "User feedback",
    send: "Send",
    feedbackTitle: "Response",
    feedbackMessage:
      "Thank you for submitting a correction to your receipt! We will look into it as soon as possible.",
    addItem: "Add product on receipt",
    reportProblem: "Report a problem",
    submitReview: "Send for a review",
    confirmTitle: "Receipt sent",
    confirmMessage:
      "Your receipt has been submitted with success and we will get back to you shortly. We will not charge anything on the top of the original receipt amount. Thank you for helping us to improve!",
    confirmButton: "Done",
    addMissingItem: "Add missing item",
    addMissingItemMessage: "Add a message (optional)",
  },
  receiptAddItem: {
    title: "Add product",
  },
  receiptsScreen: {
    receipts: "Receipts",
    detail: "$$ items",
    detailItems: {
      one: "item",
      other: "items",
    },
    headerTitle: "Receipts",
    noReceiptsMessage:
      "No receipts yet.\nEveryone likes to start with something new",
    processing1: "Processing",
    processing2: "Transaction",
    label: {
      contested: "IN REVIEW",
      draft: "DRAFT",
      empty: "EMPTY",
      paid: "PAID",
      pending: "DRAFT",
      refunded: "REFUNDED",
      reviewed: "REVISED",
      unknown: "Unknown",
      unpaid: "UNPAID",
    },
    message: {
      contested: "Contested message",
      draft: "Draft message",
      empty: "Empty message",
      paid: "Paid message",
      pending: "Pending message",
      refunded: "Refunded message",
      reviewd: "Reviewed message",
      unpaid: "Unpaid message",
    },
    resetAll: "RESET ALL",
    for: "for",
    minutes: "minutes",
    hours: "hours",
    days: "days",
    stillWorkingOnIt: "still working on it!",
  },
  settingsScreen: {
    headerTitle: "Settings",
    items: {
      about: "About",
      contact: "Contact us",
      faq: "FAQ",
      faq2: "How does the shop work?",
      logout: "Log out",
      reportProblem: "Report a problem",
      termsOfUse: "Terms of Use",
      storybook: "Storybook",
      disableNotification: "Disable notification",
    },
    security: "SECURITY",
    aboutUs: "ABOUT US",
    myCards: "My cards",
    more: "MORE",
    developer: "DEVELOPER",
    taxId: "Tax ID:",
    companyName: "Company:",
    address: "Address:",
  },
  signupScreen: {
    termsPrompt: "By signing up you agree to our ",
    termsPromptLink: "Terms of Use",
    title: "Sign up",
    privacyPolicy: "Privacy Policy",
    privacyPolicyPrefix: "and read the ",
  },
  slideModal: {
    close: "Close",
    searchPlaceholder: "Search item",
  },
  notificationPermission: {
    title: "___RETAILER___ wants to send you notifications",
    text: "I would like to receive push notifications from ___RETAILER___ regarding functioning of the automated store.",
  },
  receiptsHome: {
    cardType: "Credit card type",
    card: "Card",
    last4: "Credit card number last 4 digits",
    expDate: "Card expiration date",
    visitDate: "Date of your visit",
    howToFind: "How to find a card number?",
    submit: "Display receipts",
    registerEmail:
      "Register your email here. All future receipts will be sent directly to your email. You will not need to visit this website after visits to the store.",
    email: "E-mail address",
    registerEmailSuccess: "Email address updated successfully.",
    registerEmailFail: "Email address wasn't updated because there wasn't any receipts associated with provided credit card information and/or visit date.",
  },
};
