import React from "react";
import { FlexStyle } from "react-native";
import { BothSideTextView } from "../BothSideTextView";
import ReceiptLabel from "../ReceiptLabel";
import { receiptLabelType } from "../ReceiptLabel/styles";
import { translate } from "../../../lib/i18n";
import { dpToPx } from "../../../styles";
import { DashLine } from "../Dashline";

type SectionName = "status" | "product" | "charge" | "date" | "person";

export type DetailItem = {
  title: string;
  value?: string | null;
  rightView?: React.ReactNode;
  valueTall?: boolean;
  leftViewStyle?: FlexStyle;
  status?: ApiOrderLineStatus;
};

type DetailSectionProps = {
  name: SectionName;
  data: DetailItem[];
  noAmounts?: boolean;
};

const getMiddleView = (status: ApiOrderLineStatus) => {
  switch (status) {
    case "contested":
      return (
        <ReceiptLabel
          receiptLabelType={receiptLabelType.CONTESTED}
          title={translate("receiptsScreen.label.contested")}
        />
      );
    case "reviewed":
      return (
        <ReceiptLabel
          receiptLabelType={receiptLabelType.REVIEWED}
          title={translate("receiptsScreen.label.reviewed")}
        />
      );
    default:
      return null;
  }
};

const ReceiptDetailSection: React.FC<DetailSectionProps> = ({
  name,
  data,
  noAmounts = false,
}: DetailSectionProps) => {
  return (
    <>
      {data.map(({ title, value, rightView, valueTall, status, leftViewStyle }, index) => {
        const detailTitleStyle = valueTall
          ? { fontSize: dpToPx(20) }
          : undefined;
        const middleView = getMiddleView(status!);

        return (
          <BothSideTextView
            key={`${title}${index}`}
            title={`${title}`}
            detailTitle={!noAmounts && Boolean(value) ? value : null}
            detailTitleStyle={detailTitleStyle}
            rightView={rightView || null}
            middleView={middleView}
            leftViewStyle={leftViewStyle}
          />
        );
      })}
      {name !== "date" && <DashLine />}
    </>
  );
};

export default ReceiptDetailSection;
