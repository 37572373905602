import React from "react";
import { color2 } from "../../styles";
import { AppConfig } from "../api/types";

interface IFF {
  [key: string]: boolean | string;
}

export type CurrencyCode = "USD" | "EUR" | "PLN" | "GBP" | "CNY";

export interface IFeatureFlags extends IFF {
  taxEnabled: boolean;
  hideDraftProducts: boolean;
  productsTaxEnabled: boolean;
  isFirstData: boolean;
  isCompanyEnabled: boolean;
  isPhoneNumberEnabled: boolean;
  isAddressEnabled: boolean;
  aifiFooterText: boolean;
  isGroupShopping: boolean;
  hideProcessTime: boolean;
  isOrderDetailQrCodeEnabled: boolean;
  isStripeV2: boolean;
  isPrivacyPolicyEnabled: boolean;
  privacyPolicyLink: string;
  isDataProtectionEmailEnabled: boolean;
  dataProtectionEmail: string;
  isKeycloakEnabled: boolean;
  isNPSEnabled: boolean;
  hidePoweredByAiFi: boolean;
  orderDetailsShowHeader: boolean;
  orderDetailsShowDetailsAtTheTop: boolean;
  orderDetailsShowProductsListTitle: boolean;
  orderDetailsShowFooterOrderId: boolean;
  contestingEnabled: boolean;
  downloadReceiptEnabled: boolean;
}

export const defaultFeatureFlags: IFeatureFlags = {
  taxEnabled: false,
  hideDraftProducts: true,
  productsTaxEnabled: true,
  isFirstData: false,
  isCompanyEnabled: false,
  isPhoneNumberEnabled: false,
  isAddressEnabled: false,
  aifiFooterText: true,
  isGroupShopping: false,
  hideProcessTime: true,
  isOrderDetailQrCodeEnabled: false,
  isStripeV2: false,
  isPrivacyPolicyEnabled: false,
  privacyPolicyLink: "",
  isDataProtectionEmailEnabled: false,
  dataProtectionEmail: "dataprotection@aifi.com",
  isKeycloakEnabled: false,
  isNPSEnabled: false,
  hidePoweredByAiFi: false,
  orderDetailsShowHeader: true,
  orderDetailsShowDetailsAtTheTop: false,
  orderDetailsShowProductsListTitle: false,
  orderDetailsShowFooterOrderId: true,
  contestingEnabled: true,
  downloadReceiptEnabled: true
};

export const defaultAppConfig: AppConfig = {
  timezone: "",
  locale: 'en'
}

export interface IFlavor {
  taxValidation(_value: string): boolean;
  getGDPRText(): string | null;
  getIconColor(): string;
  getSpinnerColor(): string;
  getDashLineColor(): string;
  getFaqTranslation(): string;
  getInputBaseColor(): string;
  // getFaqAction(_navigation: NavigationScreenProp<NavigationParams>): void;
  formatCurrency(
    amount: string,
    currencyCode: CurrencyCode,
    _quantity?: number
  ): string;
  // getEmptyReceiptContent(): JSX.Element | null;
  // getOnboarding(navigation: any): JSX.Element;
  getFeatureFlags(): IFeatureFlags;
  getSupportEmail(): string | null;
  getTelephone(): string | null;
  getAddress(): string;
  getAddressPlaceholder(): string;
  getOpenHours(): string | null;
  getAboutUsLink(): string;
  getTouLink(): string;
  getCircleLogo(): JSX.Element;
  getReceiptLogo(): JSX.Element;
  getDefaultFontFamily(): string;
  getPrimaryFont(): string;
  getPrimaryFontLight(): string;
  getPrimaryFontLightest(): string;
  getSecondaryFont(): string;
  getSecondaryFontBold(): string;
  formatDateTime(dateTime: string, timezone: string): { date: string; time: string };
  getStoreData(): StoreData;
  getKeycloakEndpoint(): string;
  getCode(): string;
  getMainColor(): string;
  getLinearGradientBackground(): color2[];
  getAPIEndpoint(): string;
  getStripePublicKey(): string;
  getName(): string;
  getFullName(): string;
}

export class DefaultFlavor implements IFlavor {
  taxValidation(_value: string): boolean {
    return true;
  }

  getGDPRText(): string | null {
    return "";
  }

  getIconColor(): string {
    return color2.BLACK;
  }

  getSpinnerColor(): string {
    return color2.BLACK;
  }

  getDashLineColor(): string {
    return color2.BLACK;
  }

  getFaqTranslation(): string {
    return "";
  }

  getInputBaseColor(): string {
    return color2.BLACK;
  }

  // getFaqAction(
  //   _navigation: NavigationScreenProp<NavigationParams, NavigationParams>
  // ): void {}

  formatCurrency(): string {
    return "";
  }

  getEmptyReceiptContent(): JSX.Element | null {
    return null;
  }

  getOnboarding(): JSX.Element {
    return <></>;
  }

  getFeatureFlags(): IFeatureFlags {
    return defaultFeatureFlags;
  }

  getSupportEmail(): string | null {
    return null;
  }

  getTelephone(): string | null {
    return null;
  }

  getAddress(): string {
    return "";
  }

  getAddressPlaceholder(): string {
    return "";
  }

  getOpenHours(): string | null {
    return "";
  }

  getAboutUsLink(): string {
    return "";
  }

  getTouLink(): string {
    return "";
  }

  getCircleLogo(): JSX.Element {
    return <></>;
  }

  getReceiptLogo(): JSX.Element {
    return this.getCircleLogo();
  }

  getDefaultFontFamily(): string {
    return "";
  }

  getPrimaryFont(): string {
    return "";
  }

  getPrimaryFontLight(): string {
    return "";
  }

  getPrimaryFontLightest(): string {
    return "";
  }

  getSecondaryFont(): string {
    return "";
  }

  getSecondaryFontBold(): string {
    return "";
  }

  formatDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    return { date: dateTime, time: "" };
  }

  getStoreData(): StoreData {
    return {
      API_ENDPOINT: "",
      STRIPE_PUBLISHABLE_KEY: "",
    };
  }

  getKeycloakEndpoint(): string {
    return "";
  }

  getCode(): string {
    return "";
  }

  getMainColor(): string {
    return color2.BLACK;
  }

  getLinearGradientBackground(): color2[] {
    return [color2.BLACK];
  }

  getAPIEndpoint(): string {
    return "";
  }

  getStripePublicKey(): string {
    return "";
  }

  getName(): string {
    return "";
  }

  getFullName(): string {
    return "";
  }
}
