import { config } from "../config";

const apiUrl = (path: string) => `${config.OASIS_API_URL}${path}`;

const buildHeaders = async () => {
  return {
    "content-type": "application/json",
  };
};

const post = async (url: string, body: { [key: string]: any }) => {
  const headers = await buildHeaders();
  const req = new Request(apiUrl(url), {
    method: "POST",
    headers: {
      ...headers,
    },
    body: JSON.stringify(body),
  });
  return await fetch(req).then((response) => response.json());
};

const get = async (url: string) => {
  const headers = await buildHeaders();
  const req = new Request(apiUrl(url), {
    method: "GET",
    headers: {
      ...headers,
    },
  });
  return await fetch(req)
    .then(async (response) => {
      return { ok: response.ok, data: await response.json() };
    })
    .then(({ ok, data }) => {
      if (ok) {
        return data;
      }
      throw new Error(data.message);
    });
};

const getConfig = async () => {
  return await get(`/api/config`);
};

const handleError = console.error;

const api = { getConfig, handleError};

export default api;
