export enum color2 {
  ASTON_VILLA = '#660033',
  BLUE_DARK = '#1F2533',
  BLUE = '#295FCC',
  BLUE_AIFI = '#4979FE',
  LIGHT_BLUE2 = '#D8E6ED',
  BLUE3 = '#40B4E5',
  GREEN = '#14BF48',
  GREEN2 = '#31D021',
  RED = '#DA3717',
  RED2 = '#C74459',
  GRAY = '#EDF6FA',
  GRAY2 = '#E1EEF5',
  GRAY4 = '#B0BABF',
  GRAY5 = '#777777',
  GRAY6 = '#6B737D',
  GRAY7 = '#F9F9F9',
  GRAY8 = '#9BA9B1',
  DARK_GRAY = '#444444',
  ZABKA = '#006420',
  FACEBOOK = '#4267B2',
  GOOGLE = '#3E82F1',
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  LIGHT_BLUE = '#D3F2FF',
  LEICESTER_CITY = '#164194',
  SWITCH_TRACK_FALSE = '#767577',
  SWITCH_TRACK_TRUE = '#81B0FF',
  SWITCH_THUMB = '#F4F3F4',
  SWITCH_IOS_BGCOLOR = '#3E3E3E',
  RIO = '#D8005FAA',
  STREET = '#75C92B',
  MORRISONS = '#524794',
  MORRISONS_YELLOW = '#FCBC00',
  MORRISONS_LIGHT = '#52479422',
  MORRISONS_ONBOARDING_TEXT = '#26262688',
  INPUT_COLOR = '#F3F5F6',
  HEB_RED = '#DC291E',
  HEB_GRAY = '#4C4D4F',
  HEB_TEAL = '#00B2A9',
  HEB_RED_LOGO = '#EE3124',
  PENSKE_RED = '#E78FB5',
  PENSKE_GRAY = '#5594B7',
  TRANSPARENT = '#FFFFFFFF',
  COCONUT_RED = '#D22730',
  COCONUT_GREEN = '#789C4A',
  BOXLL_RED = '#CC071E',
  BOXLL_GREEN = '#97BF0D',
  TRANSLUCENT_BLACK = 'rgba(4, 5, 10, 0.85)',
  MIAMI_MAIN = '#00838D',
  MIAMI_SECONDARY = '#F05022',
  MSFT_MAIN = '#00A1F1',
  MSFT_SECONDARY = '#FFBB00',
  DG_MAIN = '#000000',
  DG_SECONDARY = '#1F2533',
  VERIZONULTRA_MAIN = '#e42527',
  VERIZONULTRA_SECONDARY = '#ffffff',
  VERIZONULTRAUK_MAIN = '#e42527',
  VERIZONULTRAUK_SECONDARY = '#ffffff',
}
