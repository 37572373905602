import React, { useContext } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { styles } from "./styles";
import { Button } from "../../../../components/v2/Button";
import { translate } from "../../../../lib/i18n";
import { MasterContext } from "src/lib/masterContext";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import { Icon } from "src/assets/icons/icon";
import { Container, Row, Col } from "reactstrap";

interface IReceiptFooter {
  showSubmit: boolean;
  onSubmit: () => void;
  submitText?: string;
  loading?: boolean;
  orderId?: string;
  footerText?: string;
  isSubmitDisabled?: boolean;
  extraText?: string;
}

const ReceiptFooter = ({
  extraText,
  onSubmit,
  showSubmit,
  submitText,
  loading = false,
  orderId,
  footerText,
  isSubmitDisabled,
}: IReceiptFooter) => {
  const {
    flavor,
    featureFlags: { downloadReceiptEnabled },
  } = useContext(MasterContext);

  const title = submitText || translate("receiptDetailComplain.reportProblem");

  return (
    <>
      <Container>
        <Row>
          <Col xs={{ size: 6, offset: 3 }}>
            <View style={styles.addressWrapper}>
              {orderId && (
                <>
                  <Text style={styles.address}>
                    {`${translate("receiptDetailScreen.orderId")}\n`}
                    {orderId}
                  </Text>
                  <Text style={styles.star}>* * *</Text>
                </>
              )}
              {footerText && <Text style={styles.address}>{footerText}</Text>}
            </View>
          </Col>
          {downloadReceiptEnabled && (
            <Col xs="3" className="text-center m-auto">
              <Icon
                width={45}
                height={45}
                onClick={() => window.print()}
                icon="DownloadIcon"
              />
            </Col>
          )}
        </Row>
        {extraText && (
          <Row>
            <Col>
              <Text style={styles.extraText}>{extraText}</Text>
            </Col>
          </Row>
        )}
      </Container>
      {showSubmit &&
        (loading ? (
          <ActivityIndicator color={flavor.getSpinnerColor()} />
        ) : (
          <Button
            disabled={isSubmitDisabled}
            style={styles.submitButton}
            title={title}
            onPress={onSubmit}
          />
        ))}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <PoweredByAiFi />
      </div>
    </>
  );
};

export default ReceiptFooter;
