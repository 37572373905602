import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { currencyMap } from "./constants";
import { translate } from "./i18n";
import { isEmpty, isNil } from "ramda";

dayjs.extend(utc);
dayjs.extend(tz);

export interface IReceiptItem {
  id: string;
  orderId: string;
  name: string;
  quantity: number;
  isDraft: boolean;
  rin: string;
  image: string | null;
  totalPrice?: string;
}

export const getOrdersFromApiOrders = (
  apiOrders: { orders: ApiOrder[] } | { draftOrders: ApiOrder[] } | void
) =>
  // eslint-disable-next-line no-nested-ternary
  apiOrders
    ? "orders" in apiOrders
      ? apiOrders.orders
      : apiOrders.draftOrders
    : [];

export const getTimeSpent = (createdAt: string, processedAt: string) => {
  const createdDate = new Date(createdAt);
  const processedDate = new Date(processedAt);
  const diffMs = processedDate.getTime() - createdDate.getTime();

  const days = Math.floor(diffMs / 86400000);
  const hours = Math.floor((diffMs % 86400000) / 3600000);
  const mins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  if (days > 0) {
    return `${days} ${translate("receiptsScreen.days")} ${hours} ${translate(
      "receiptsScreen.hours"
    )} ${mins} ${translate("receiptsScreen.minutes")}`;
  }

  if (hours > 0) {
    return `${hours} ${translate("receiptsScreen.hours")} ${mins} ${translate(
      "receiptsScreen.minutes"
    )}`;
  }
  return `${mins} ${translate("receiptsScreen.minutes")}`;
};

export const formatCurrency = (
  amount: string,
  currencyCode: CurrencyCode,
  _quantity?: number
): string => {
  return `${currencyMap[currencyCode] || ""}${parseFloat(amount).toFixed(2)}`;
};

export const timezoneDate = (dateTime: string, timezone: string) => {
  let date = dayjs(dateTime);
  if (!isNil(timezone) && !isEmpty(timezone)) {
    date = date.tz(timezone);
  }
  return date;
};

export const formatDateTime = (dateTime: string, timezone: string) => {
  const date = timezoneDate(dateTime, timezone);
  const formatDate = "DD.MM.YYYY";
  const formatTime = "HH:mm";

  return {
    date: date.format(formatDate),
    time: date.format(formatTime),
  };
};

export const formatTaxName = (name: string, rate?: string) => {
  const parsedRate = parseFloat(rate ?? '')
  
  if (isNaN(parsedRate)) {
    return name
  }

  // e.g. formatTaxName('VAT', '0.23') => 'VAT=23%'
  return name  + '=' +  Number((parsedRate * 100).toFixed(2)).toString() + "%"
}
