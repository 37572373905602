import React, { useContext } from "react";
import { Row } from "reactstrap";
import { MasterContext } from "src/lib/masterContext";
import { formatCurrency } from "src/lib/utils";

import { ReceiptInfo } from "./types";

interface ReceiptsListProps {
  receipts: ReceiptInfo[];
}

export const ReceiptsList: React.FC<ReceiptsListProps> = ({ receipts }) => {
  const { flavor, appConfig: {timezone} } = useContext(MasterContext);
  return (
    <>
      <Row style={{ padding: "0 10px" }}>
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th>Status</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          {receipts.map((x) => (
            <tr key={x.id}>
              <td>{flavor.formatDateTime(x.createdAt, timezone).time}</td>
              <td>{x.status.toLocaleUpperCase()}</td>
              <td>{formatCurrency(x.totalPrice, x.currencyCode)}</td>
              <td style={{ textAlign: "right" }}>
                <a
                  rel="noreferrer"
                  href={`/receipts/${x.id}/${x.sessionToken}`}
                  target="_blank"
                >
                  View
                </a>
              </td>
            </tr>
          ))}
        </table>
      </Row>
    </>
  );
};
