import { defaultAppConfig } from './../flavors/flavorTypes';
import { AppConfig } from './../../../../web-receipt-api/src/config';
import { useCallback, useEffect, useState } from "react";
import { setShopData } from "../api/storage";
import { Flavor } from "../flavors";
import { getFlavor } from "../flavors/utils";
import { IMasterContext } from "../masterContext";
import api from '../../lib/api/api';
import { setLocale } from '../i18n';

export const useMasterContext = (flavorName: string): IMasterContext => {
  const flavClass = getFlavor(flavorName);
  const [flavor, setFlavor] = useState<Flavor>(flavClass);
  const featureFlags = flavClass.getFeatureFlags();
  const [appConfig, setAppConfig] = useState<AppConfig>(defaultAppConfig)

  const setStoreData = (storeData: StoreData) =>
    setShopData(JSON.stringify(storeData));

  const fetchAndSetAppConfig = useCallback(async () => {
    const response = await api.getConfig();
    if (response) {
      setAppConfig(response)
      setLocale(response.locale)
    }
  }, [])

  useEffect(() => {
    fetchAndSetAppConfig()
  }, [fetchAndSetAppConfig])

  return {
    setStoreData,
    flavor,
    setFlavor,
    featureFlags,
    appConfig,
    setAppConfig
  };
};
